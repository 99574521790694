import { createAnalytics } from '@rogu/tracking-sdk';

import fetcher from '@/shared/fetcher';

const { trackEvent } = createAnalytics({
  appName: 'rea-web',
  fetcher: (payload) => {
    return fetcher({
      ...payload,
      disableNodeProxy: false,
    }).catch(() => {}) as Promise<Response>;
  },
});

export default trackEvent;