import getConfig from 'next/config';
import {configure} from '@rogu/fetch';

const { publicRuntimeConfig } = getConfig();

const fetcher = configure({
  // Set default headers for every request
  headers: {
    country: publicRuntimeConfig.country,
  },
  options: {
    mode: 'no-cors', // no-cors, *cors, same-origin
  },
  server: {
    // host for serverside
    host: 'http://gw.rg.internal',
  },
  client: {
    // host for client side
    host: publicRuntimeConfig.apiEndpoint,
    onRefreshTokenFailed: () => {
      //do nothing
    },
  },
});

export default fetcher;