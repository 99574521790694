import { memo } from 'react';
import Head from 'next/head';
import { BoxProps, Flex, Box, Text } from '@chakra-ui/layout';

import { PageHeader } from './PageHeader';
import { PageFooter } from './PageFooter';

import { getConfig } from '@/shared/getEnv';
import { PageCommunity, PageGeneral, PageUpcomingEvent } from '@/modules/home/homeEntity';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import FloatingBox from './FloatingBox';

interface PageProps extends BoxProps {
  data: PageGeneral;
  withFooter?: boolean;
  isIndexPage?: boolean;
  isFollowPage?: boolean;
  canonical?: string;
  events: PageUpcomingEvent;
  community?: PageCommunity
}

const { publicRuntimeConfig: config } = getConfig();

function makeCanonical(pathname: string) {
  return config.origin + config.basePath + pathname;
}

const isIndexSite = config.origin.includes(config.noIndexOrigin) !== true;

const MetaRobot = memo(
  ({ isIndex, isFollow }: { isIndex: boolean; isFollow: boolean }) => {
    if (isIndexSite === false) {
      return <meta name="robots" content="noindex, nofollow" />;
    }

    const indexStr = isIndex ? 'index' : 'noindex';
    const followStr = isFollow ? 'follow' : 'nofollow';

    return <meta name="robots" content={`${indexStr}, ${followStr}`} />;
  },
);

function Page({
  data,
  withFooter = true,
  isIndexPage = true,
  isFollowPage = true,
  children,
  canonical,
  events,
  community,
  ...props
}: PageProps) {
  return (
    <Flex direction="column" minHeight="100vh">
      <Head>
        <MetaRobot isIndex={isIndexPage} isFollow={isFollowPage} />
        {canonical && <link key="canonical" rel="canonical" href={canonical} />}
      </Head>
      <PageHeader data={data} events={events} community={community}/>
      <Box flex="1" {...props}>
        {children}
      </Box>
      {withFooter && <PageFooter data={data} />}
      {/* <FloatingBox duration={5000}>
        <Text>Your text here</Text>
        <Text>Some other text here</Text>
        <Text>And one more text here</Text>
      </FloatingBox> */}
    </Flex>
  );
}

function PageAds({
  data,
  withFooter = true,
  isIndexPage = false,
  isFollowPage = false,
  children,
  canonical,
  events,
  ...props
}: PageProps) {
  return (
    <Flex direction="column" minHeight="100vh">
      <Head>
        <MetaRobot isIndex={isIndexPage} isFollow={isFollowPage} />
        {canonical && <link key="canonical" rel="canonical" href={canonical} />}
      </Head>
      <Box flex="1" {...props}>
        {children}
      </Box>
      {withFooter && <PageFooter data={data} />}
      {/* <FloatingBox duration={5000}>
        <Text>Your text here</Text>
        <Text>Some other text here</Text>
        <Text>And one more text here</Text>
      </FloatingBox> */}
    </Flex>
  );
}

export type { PageProps };
export { Page, PageAds ,makeCanonical };
